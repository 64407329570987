import { useState } from "react";
import styled from "styled-components";
import { Dialog } from '@mui/material';

import { HBox, VBox } from "../../components/Containers";
import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { Button } from "../../components/Buttons";
import colors from "../../config/colors";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const InfoDlg = ({ doctorName }) => {
    const [open, setOpen] = useState(true);

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose} fullWidth>
            {/* <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Reason</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line /> */}
                <H2 color='first' className="m-2">Hello {doctorName},</H2>
                <P1 className="m-2">
                    We have sent the zoom meeting link to the patient.
                    Patient is joining very soon. Please wait a moment.
                    In the mean time please close this dialog and click <span style={{ color: colors.blue, fontWeight: 'bold' }}>"Join"</span> button to enter into the meeting.</P1>
                <P1 className="mx-2 mb-2" color='second'>Thank you!</P1>
            <Line />
            <HBox align="center" justify="flex-end" className="px-2 py-1">
                <CloseBtn size="sm" onClick={handleClose}>Close</CloseBtn>
            </HBox>

        </Dialog>
    )
}

export default InfoDlg;
import styled from "styled-components";

import { VBox } from "../../components/Containers";
import { Button } from "../../components/Buttons";
import { H1, H2, H3 } from "../../components/Typography";
import colors from "../../config/colors";

const Container = styled(VBox)`
    height: 100vh;
    width: 100vw;
    background-color: ${colors.lightBlue};
`

const Close = () => {
    return (
        <Container align='center' justify='center'>
            <VBox align='center'>
                <H1 className="bold mb-2">Thank You for Joining</H1>
                {/* <Button
                    size='lg'
                    color='second'
                    onClick={() => window.close()}
                >
                    Close
                </Button> */}
                <H3 color='second'>Please Close the Window</H3>
            </VBox>
        </Container>
    )
}

export default Close;
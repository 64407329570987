import { ZoomMtg } from '@zoomus/websdk';
import { useParams } from 'react-router-dom';

import '@zoomus/websdk/dist/css/bootstrap.css';
import '@zoomus/websdk/dist/css/react-select.css';
import './index.css';
import InfoDlg from './InfoDlg';

const KJUR = require('jsrsasign');

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.9.5/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function Meeting() {
    const { type, meetingNumber, passWord, userName } = useParams();

    // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
    var sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY;
    var sdkSecret = process.env.REACT_APP_ZOOM_SDK_SECRET;
    // var meetingNumber = '89798038517';
    var role = 0;
    var leaveUrl = process.env.REACT_APP_ZOOM_LEAVE_URL;
    // var userName = 'Doctor';
    var userEmail = '';
    // var passWord = '123456';
    // pass in the registrant's token if your meeting or webinar requires registration. More info here:
    // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
    // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
    var registrantToken = '';

    // https://www.npmjs.com/package/jsrsasign
    function generateSignature() {
        return new Promise((res, rej) => {
            const iat = Math.round((new Date().getTime() - 30000) / 1000);
            const exp = iat + 60 * 60 * 2;
            const oHeader = { alg: 'HS256', typ: 'JWT' }

            const oPayload = {
                sdkKey: sdkKey,
                mn: meetingNumber,
                role: role,
                iat: iat,
                exp: exp,
                appKey: sdkKey,
                tokenExp: iat + 60 * 60 * 2
            }

            const sHeader = JSON.stringify(oHeader);
            const sPayload = JSON.stringify(oPayload);
            const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret);

            res(sdkJWT);
        });
    }
    generateSignature().then((res) => startMeeting(res)).catch(error => console.log(error));

    function startMeeting(signature) {
        document.getElementById('zmmtg-root').style.display = 'block';

        ZoomMtg.init({
            leaveUrl: leaveUrl,
            success: (success) => {
                console.log(success)

                ZoomMtg.join({
                    signature: signature,
                    meetingNumber: meetingNumber,
                    userName: userName,
                    sdkKey: sdkKey,
                    userEmail: userEmail,
                    passWord: passWord,
                    tk: registrantToken,
                    success: (success) => {
                        console.log(success)
                    },
                    error: (error) => {
                        console.log(error);
                    }
                })

            },
            error: (error) => {
                console.log(error);
            }
        })
    }

    return (
        <div>
            <main>
                <h1>Zoom Meeting</h1>
            </main>
            
            {type==='d' && <InfoDlg doctorName={userName} />}
        </div>
    );
}

export default Meeting;
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import './App.css';
import Meeting from './pages/Meeting';
import Close from './pages/Close';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/meeting/:type/:meetingNumber/:passWord/:userName" element={<Meeting />} />
                <Route path='/close' element={<Close />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
